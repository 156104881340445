<template>
  <div>
    <v-app-bar flat color="transparent" class="main-appbar">
      <app-bar-nav-icon />
      <v-toolbar-title class="headline font-weight-bold"
        >Create Program</v-toolbar-title
      >

      <v-spacer />
      <v-btn class="ml-5" :loading="programLoading" @click="submit()">
        Create Program
      </v-btn>
    </v-app-bar>

    <v-form
      class="program-form"
      @submit.prevent="isUpdating ? update() : submit()"
    >
      <v-row>
        <v-col cols="4">
          <ImageUploader :form="form" />

          <div
            class="upload-container pointer mt-5"
            :class="{ 'has-error': form.$getError('video_thumb_nail') }"
            @click="uploadThumbnail('video')"
          >
            <v-img
              :src="require('@/assets/icons/file-upload-placeholder.svg')"
              alt="File Upload Placeholder"
              height="150px"
              width="auto"
            />

            <div class="upload-label mt-3">
              <span variant="primary">
                Upload Introduction Video
              </span>
            </div>

            <v-overlay :absolute="true" :value="uploadLoader">
              <v-progress-circular indeterminate></v-progress-circular>
            </v-overlay>
          </div>
          <div
            class="upload-container pointer mt-5"
            @click="uploadThumbnail('video')"
            v-if="checkIVideoThumbNail(form)"
          >
            <video controls :src="form.video_thumb_nail[0].url">
              Your browser does not support the video tag.
            </video>
          </div>
        </v-col>
        <v-col cols="8">
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model.trim="form.title"
                class="input__outlined--regular"
                label="Program Title"
                outlined
                :error-messages="form.$getError('title')"
                @input="form.$clearError('title')"
                hide-details="auto"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6">
              <v-select
                v-model="form.iap_product_id"
                class="input__outlined--regular"
                label="Product ID"
                outlined
                :items="productIds"
                :error-messages="form.$getError('iap_product_id')"
                @change="form.$clearError('iap_product_id')"
              />
            </v-col>
            <v-col cols="6">
              <v-select
                v-model="form.category_id"
                class="input__outlined--regular"
                label="Category"
                outlined
                :items="programCategories"
                item-value="id"
                item-text="label"
                :error-messages="form.$getError('category_id')"
                @change="form.$clearError('category_id')"
              />
            </v-col>
            <!-- <v-col cols="6">
              <v-text-field
                v-model.trim="form.price"
                class="input__outlined--regular"
                label="Price"
                outlined
                type="number"
                :error-messages="form.$getError('price')"
                @input="form.$clearError('price')"
              />
            </v-col> -->
          </v-row>
          <ContentEditor
            v-model="form.content"
            class="content-editor"
            :class="{ 'has-error': form.$getError('content') }"
          />

          <h2 class="my-5">Program Exercises</h2>
          <v-card outlined class="pa-5">
            <div class="d-flex justify-space-between align-center mb-3">
              <p>Add Weekly Exercises</p>
              <v-btn @click="openForm">
                Add Week
              </v-btn>
            </div>

            <v-card
              flat
              outlined
              class="mb-3"
              v-if="weekly_exercises.length > 0"
            >
              <v-list-item-group>
                <v-list-item
                  v-for="(week, index) in weekly_exercises"
                  :key="index"
                >
                  <v-list-item-content>
                    <v-list-item-title>{{
                      `Week ${week.week}`
                    }}</v-list-item-title>
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-menu>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn icon v-bind="attrs" v-on="on">
                          <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                        </v-btn>
                      </template>

                      <v-list>
                        <v-list-item
                          v-for="(item, i) in menuItems"
                          link
                          :key="i"
                        >
                          <v-list-item-title
                            @click="menuAction(item, week, index)"
                            >{{ item }}</v-list-item-title
                          >
                        </v-list-item>
                      </v-list>
                    </v-menu>
                  </v-list-item-action>
                </v-list-item>
              </v-list-item-group>
            </v-card>
          </v-card>
        </v-col>
      </v-row>
    </v-form>

    <WeekForm
      v-if="showWeekForm"
      :show="showWeekForm"
      :exercises="exercises"
      :categories="exerciseCategories"
      :isEditWeek="isEditWeek"
      :current_program="current_program"
      :action="isEditWeek ? 'Update' : 'Add'"
      @onCancel="cancel"
      @onSave="addWeek"
      @onUpdate="updateWeek"
    />

    <MediaUploader
      :ref="'triggerUpload'"
      @onMediaLoad="onMediaLoad"
      :mediaType="mediaType"
      @startUpload="startUpload"
    ></MediaUploader>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import ControlsMixin from '@/utils/mixins/Controls'
import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon'
import ImageUploader from '@/components/ImageUploader'
import ContentEditor from '@/components/ContentEditor'
import WeekForm from '@/components/program/WeekForm'
import { objectToFormData } from '@/utils/jsonToFormData'
import { mdiDotsVertical } from '@mdi/js'
import { programMenu } from '@/utils/constants'
import MediaUploader from '@/components/MediaUploader'

export default {
  name: 'CreateProgram',
  mixins: [ControlsMixin],
  components: {
    AppBarNavIcon,
    ImageUploader,
    ContentEditor,
    WeekForm,
    MediaUploader
  },
  computed: {
    ...mapState({
      form: state => state.program.programForm,
      productIds: state => state.program.productIds,
      programCategories: state => state.programCategory.categories,
      exerciseCategories: state => state.exerciseCategory.categories,
      programLoading: state => state.program.programLoading
    }),
    ...mapGetters({
      exercises: 'exercise/contentExercises'
    })
  },
  data() {
    return {
      showWeekForm: false,
      weekly_exercises: [],
      icons: {
        mdiDotsVertical
      },
      current_program: {
        week: null,
        daily_exercises: []
      },
      menuItems: [programMenu().EDIT, programMenu().DELETE],
      isEditWeek: false,
      editIndex: null,
      mediaType: null,
      uploadLoader: false,
      imageState: null
    }
  },
  mounted() {
    this.form.$reset()
    this.getProgramCategories()
    this.getExercieCategories()
    this.getProductIds()
    this.current_program.week = this.weekly_exercises.length + 1
  },
  methods: {
    ...mapActions({
      getProgramCategories: 'programCategory/getProgramCategories',
      getExercieCategories: 'exerciseCategory/getExercieCategories',
      createProgram: 'program/createProgram',
      getExercises: 'exercise/getExercises',
      getProductIds: 'program/getProductIds'
    }),
    loadExercises(id) {
      this.getExercises({ category: id })
    },
    startUpload() {
      this.uploadLoader = true
    },
    addWeek(week) {
      this.weekly_exercises.push(week)
      this.clearCurrentProgram()
      this.showWeekForm = false
    },
    updateWeek(week) {
      this.weekly_exercises[this.editIndex] = week
      this.isEditWeek = false
      this.clearCurrentProgram()
      this.editIndex = null
      this.showWeekForm = false
    },
    openForm() {
      this.showWeekForm = true
    },
    clearCurrentProgram() {
      this.current_program = {
        week: this.weekly_exercises.length + 1,
        daily_exercises: []
      }
    },
    uploadThumbnail(state) {
      if (state === 'video') {
        this.mediaType = 'video'
      } else {
        this.mediaType = 'image'
      }

      this.$refs.triggerUpload.openFile()
      this.imageState = state
    },
    onMediaLoad(data) {
      switch (this.imageState) {
        case 'video':
          this.form.video_thumb_nail = []
          this.form.video_thumb_nail = [
            {
              file: data.file.get('file'),
              url: data.url
            }
          ]
          this.uploadLoader = false
          break
      }
    },
    menuAction(menu, week, index) {
      switch (menu) {
        case programMenu().EDIT:
          this.isEditWeek = true
          this.current_program = week
          this.editIndex = index
          this.showWeekForm = true
          break
        case programMenu().DELETE:
          if (!this.isEditWeek) {
            this.weekly_exercises.splice(index, 1)
          }
          break
      }
    },
    cancel() {
      this.showWeekForm = false
      this.clearCurrentProgram()
    },
    checkIVideoThumbNail(image) {
      return image?.video_thumb_nail?.[0]?.url || image?.video_thumb_nail?.url
    },
    submit() {
      this.weekly_exercises = this.weekly_exercises.map(week => {
        week.daily_exercises = week.daily_exercises.map(day => {
          if (!(day.thumb_nail instanceof File)) {
            day.thumb_nail = day.thumb_nail[0].file
          }
          return day
        })
        return week
      })

      this.form.weekly_exercises = this.weekly_exercises
      const { photos, video_thumb_nail, ...program } = this.form.$data()

      const form = objectToFormData(program)
      form.append('thumb_nail', photos[0]?.file)
      if (video_thumb_nail) {
        form.append('video_thumb_nail', video_thumb_nail[0]?.file)
      }

      this.createProgram(form)
    }
  }
}
</script>

<style lang="scss" scoped>
.content-editor {
  position: relative;
  &.has-error {
    border: 2px solid #fa4856;
  }
}
.upload-container {
  width: 100%;
  height: 300px;
  border-radius: 6px;
  border: dashed 2px #2c66d54d;
  background-color: #2c66d50a;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  cursor: pointer;
  user-select: none;

  &.has-error {
    border: dashed 2px #fa4856;
  }

  .v-image {
    flex-grow: 0;
  }

  .upload-label span {
    box-shadow: 0 12px 20px rgba(44, 102, 213, 0.4);
    border-color: #0062cc;
    background-color: #0062cc;
    color: #ffffff;
    padding: 4px 32px;
    border-radius: 8px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    margin-bottom: 12px;
  }

  video {
    height: auto;
    width: 100%;
  }
}
</style>
