var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto mt-5 pa-5",attrs:{"outlined":""}},[_c('div',{staticClass:"exercises"},[_c('v-text-field',{attrs:{"outlined":"","required":"","label":"Week","prefix":"Week"},on:{"input":_vm.updateWeekTitle},model:{value:(_vm.week.week),callback:function ($$v) {_vm.$set(_vm.week, "week", $$v)},expression:"week.week"}}),(_vm.week.daily_exercises.length > 0)?_c('v-card',{staticClass:"mb-3",attrs:{"flat":"","outlined":""}},[_c('v-list-item-group',_vm._l((_vm.week.daily_exercises),function(day,index){return _c('v-list-item',{key:index},[_c('v-list-item-avatar',[_c('v-img',{attrs:{"src":_vm.renderThumbnail(day)}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(("Day " + (day.day))))])],1),_c('v-list-item-action',[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(_vm._s(_vm.icons.mdiDotsVertical))])],1)]}}],null,true)},[_c('v-list',_vm._l((_vm.menuItems),function(item,i){return _c('v-list-item',{key:i,attrs:{"link":""}},[_c('v-list-item-title',{on:{"click":function($event){return _vm.menuAction(item, day, index)}}},[_vm._v(_vm._s(item))])],1)}),1)],1)],1)],1)}),1)],1):_vm._e(),_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[(_vm.checkThumbNail(_vm.currentDay))?_c('div',{staticClass:"image-container",on:{"click":_vm.uploadThumbnail}},[_c('v-img',{attrs:{"max-height":"140px","src":_vm.currentDay.thumb_nail[0]
                ? _vm.currentDay.thumb_nail[0].url
                : _vm.currentDay.thumb_nail.url,"alt":"File Upload Placeholder"}})],1):_c('div',{staticClass:"upload-container pointer",on:{"click":_vm.uploadThumbnail}},[_c('v-img',{attrs:{"src":require('@/assets/icons/file-upload-placeholder.svg'),"alt":"File Upload Placeholder","width":"80px","height":"80px"}}),_c('div',{staticClass:"upload-label mt-3"},[_c('span',{attrs:{"variant":"primary"}},[_vm._v(" Upload day thumbnail ")])])],1)]),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{attrs:{"outlined":"","required":"","label":"Day","prefix":"Day"},model:{value:(_vm.currentDay.day),callback:function ($$v) {_vm.$set(_vm.currentDay, "day", $$v)},expression:"currentDay.day"}}),_c('v-select',{staticClass:"input__outlined--regular",attrs:{"label":"Category","hide-details":"auto","outlined":"","items":_vm.categories,"item-value":"id","item-text":"label"},on:{"change":_vm.onLoadExercises},model:{value:(_vm.catId),callback:function ($$v) {_vm.catId=$$v},expression:"catId"}})],1),(_vm.exercises.length > 0)?_c('v-list',{attrs:{"flat":"","two-line":"","width":"100%"}},[_c('v-list-item-group',{attrs:{"multiple":""},on:{"change":_vm.changed},model:{value:(_vm.selectedExercises),callback:function ($$v) {_vm.selectedExercises=$$v},expression:"selectedExercises"}},[_vm._l((_vm.exercises),function(exercise,index){return [(!exercise)?_c('v-divider',{key:("divider-" + index)}):_c('v-list-item',{key:("item-" + index),attrs:{"value":exercise.id},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                var active = ref.active;
return [_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":active,"true-value":exercise.id}})],1),_c('v-list-item-avatar',{staticClass:"mr-3"},[_c('v-img',{attrs:{"src":_vm.get(exercise, 'thumb_nail.url'),"lazy-src":_vm.get(exercise, 'thumb_nail.thumb_url'),"alt":_vm.get(exercise, 'thumb_nail.name')}})],1),_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(exercise.title))]),_c('v-list-item-subtitle',[_c('p',{domProps:{"innerHTML":_vm._s(exercise.content)}})])],1)]}}],null,true)}),(_vm.isInContents(exercise))?_c('v-row',{key:("divider-" + index),staticClass:"mx-3",attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{staticClass:"mr-2",attrs:{"outlined":"","required":"","label":"Number of Sets","type":"number","hide-details":"auto","min":"1"},model:{value:(
                    _vm.currentDay.contents[_vm.findIndex(exercise)].number_of_sets
                  ),callback:function ($$v) {_vm.$set(_vm.currentDay.contents[_vm.findIndex(exercise)], "number_of_sets", $$v)},expression:"\n                    currentDay.contents[findIndex(exercise)].number_of_sets\n                  "}})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-text-field',{staticClass:"mr-2",attrs:{"outlined":"","required":"","label":"Number of Reps","type":"number","hide-details":"auto","min":"1"},model:{value:(
                    _vm.currentDay.contents[_vm.findIndex(exercise)].number_of_reps
                  ),callback:function ($$v) {_vm.$set(_vm.currentDay.contents[_vm.findIndex(exercise)], "number_of_reps", $$v)},expression:"\n                    currentDay.contents[findIndex(exercise)].number_of_reps\n                  "}})],1)],1):_vm._e()]})],2)],1):_vm._e()],1),(_vm.currentDay.contents.length > 0)?_c('v-btn',{attrs:{"disabled":!_vm.checkDisabledDay,"loading":_vm.programLoading},on:{"click":function($event){_vm.isEdit ? _vm.updateDay() : _vm.addDay()}}},[_vm._v(" "+_vm._s(((_vm.isEdit ? 'Update' : 'Add') + " Day " + (_vm.currentDay.day)))+" ")]):_vm._e(),_c('MediaUploader',{ref:'triggerUpload',on:{"onMediaLoad":_vm.onMediaLoad}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }