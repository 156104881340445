<template>
  <v-card class="mx-auto mt-5 pa-5" outlined>
    <div class="exercises">
      <v-text-field
        outlined
        required
        label="Week"
        prefix="Week"
        v-model="week.week"
        @input="updateWeekTitle"
      ></v-text-field>

      <!-- Days -->
      <v-card flat outlined class="mb-3" v-if="week.daily_exercises.length > 0">
        <v-list-item-group>
          <v-list-item
            v-for="(day, index) in week.daily_exercises"
            :key="index"
          >
            <v-list-item-avatar>
              <v-img :src="renderThumbnail(day)"></v-img>
            </v-list-item-avatar>
            <v-list-item-content>
              <v-list-item-title>{{ `Day ${day.day}` }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-menu>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn icon v-bind="attrs" v-on="on">
                    <v-icon>{{ icons.mdiDotsVertical }}</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item v-for="(item, i) in menuItems" link :key="i">
                    <v-list-item-title @click="menuAction(item, day, index)">{{
                      item
                    }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </v-list-item-action>
          </v-list-item>
        </v-list-item-group>
      </v-card>

      <v-row>
        <v-col cols="6">
          <div
            class="image-container"
            v-if="checkThumbNail(currentDay)"
            @click="uploadThumbnail"
          >
            <v-img
              max-height="140px"
              :src="
                currentDay.thumb_nail[0]
                  ? currentDay.thumb_nail[0].url
                  : currentDay.thumb_nail.url
              "
              alt="File Upload Placeholder"
            />
          </div>
          <div v-else class="upload-container pointer" @click="uploadThumbnail">
            <v-img
              :src="require('@/assets/icons/file-upload-placeholder.svg')"
              alt="File Upload Placeholder"
              width="80px"
              height="80px"
            />

            <div class="upload-label mt-3">
              <span variant="primary">
                Upload day thumbnail
              </span>
            </div>
          </div>
        </v-col>
        <v-col cols="6">
          <v-text-field
            outlined
            required
            label="Day"
            prefix="Day"
            v-model="currentDay.day"
          ></v-text-field>
          <v-select
            v-model="catId"
            class="input__outlined--regular"
            label="Category"
            hide-details="auto"
            outlined
            :items="categories"
            item-value="id"
            item-text="label"
            @change="onLoadExercises"
          />
        </v-col>

        <v-list flat two-line width="100%" v-if="exercises.length > 0">
          <v-list-item-group
            multiple
            v-model="selectedExercises"
            @change="changed"
          >
            <template v-for="(exercise, index) in exercises">
              <v-divider v-if="!exercise" :key="`divider-${index}`"></v-divider>
              <v-list-item :key="`item-${index}`" v-else :value="exercise.id">
                <template v-slot:default="{ active }">
                  <v-list-item-action>
                    <v-checkbox
                      :input-value="active"
                      :true-value="exercise.id"
                    />
                  </v-list-item-action>
                  <v-list-item-avatar class="mr-3">
                    <v-img
                      :src="get(exercise, 'thumb_nail.url')"
                      :lazy-src="get(exercise, 'thumb_nail.thumb_url')"
                      :alt="get(exercise, 'thumb_nail.name')"
                    />
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title class="mb-2">
                      {{ exercise.title }}</v-list-item-title
                    >
                    <v-list-item-subtitle>
                      <p v-html="exercise.content" />
                    </v-list-item-subtitle>
                  </v-list-item-content>
                </template>
              </v-list-item>
              <v-row
                class="mx-3"
                :key="`divider-${index}`"
                v-if="isInContents(exercise)"
                align="center"
                justify="center"
              >
                <v-col cols="6">
                  <v-text-field
                    outlined
                    required
                    label="Number of Sets"
                    type="number"
                    hide-details="auto"
                    min="1"
                    v-model="
                      currentDay.contents[findIndex(exercise)].number_of_sets
                    "
                    class="mr-2"
                  ></v-text-field>
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    outlined
                    required
                    label="Number of Reps"
                    type="number"
                    hide-details="auto"
                    min="1"
                    v-model="
                      currentDay.contents[findIndex(exercise)].number_of_reps
                    "
                    class="mr-2"
                  ></v-text-field>
                </v-col>
              </v-row>
            </template>
          </v-list-item-group>
        </v-list>
      </v-row>
      <v-btn
        :disabled="!checkDisabledDay"
        @click="isEdit ? updateDay() : addDay()"
        v-if="currentDay.contents.length > 0"
        :loading="programLoading"
      >
        {{ `${isEdit ? 'Update' : 'Add'} Day ${currentDay.day}` }}
      </v-btn>

      <MediaUploader
        :ref="'triggerUpload'"
        @onMediaLoad="onMediaLoad"
      ></MediaUploader>
    </div>
  </v-card>
</template>

<script>
import get from 'lodash/get'
import MediaUploader from '@/components/MediaUploader'
import { mdiDotsVertical } from '@mdi/js'
import { programMenu } from '@/utils/constants'
import { mapState } from 'vuex'

export default {
  props: {
    week: {
      type: Object,
      required: true
    },
    categories: {
      type: Array,
      required: false
    },
    exercises: {
      type: Array,
      required: false
    },
    currentDay: {
      type: Object,
      required: true
    },
    isEdit: {
      type: Boolean,
      default: false
    },
    value: Object
  },
  components: {
    MediaUploader
  },
  watch: {
    isEdit: function(newVal, oldVal) {
      this.selectedExercises = this.currentDay.contents.map(content =>
        content.exercise ? content.exercise.id : content.id
      )
    },
    currentDay: function(newVal, oldVal) {
      this.selectedExercises = this.currentDay.contents.map(content =>
        content.exercise ? content.exercise.id : content.id
      )
    },
    exercises: function(newVal, oldVal) {
      this.selectedExercises = [...this.selectedExercises]
    }
    // checkDisabledDay: function(newVal, oldVal) {
    //   this.$emit('enableWeek', newVal)
    // }
  },
  data() {
    return {
      icons: {
        mdiDotsVertical
      },
      menuItems: [programMenu().EDIT, programMenu().DELETE],
      selectedExercises: []
    }
  },
  computed: {
    catId: {
      get() {
        if (this.currentDay.category) {
          return this.currentDay.category.id
        } else {
          return this.currentDay.category_id
        }
      },
      set(val) {
        if (this.currentDay.category) {
          this.currentDay.category.id = val
        } else {
          this.currentDay.category_id = val
        }
      }
    },
    checkDisabledDay() {
      return (
        this.week.week &&
        this.currentDay.day &&
        (this.currentDay?.category?.id || this.currentDay?.category_id) &&
        this.currentDay.thumb_nail &&
        this.currentDay.contents.length > 0 &&
        this.checkEmptyContents
      )
    },
    checkEmptyContents() {
      return !this.currentDay.contents.some(
        content =>
          content.number_of_sets === null ||
          content.number_of_sets === '' ||
          content.number_of_reps === null ||
          content.number_of_reps === ''
      )
    },
    ...mapState({
      programLoading: state => state.program.programLoading
    })
  },
  methods: {
    onLoadExercises(id) {
      this.$emit('onLoadExercises', id)
    },
    addDay() {
      this.$emit('addDay')
      this.selectedExercises = []
    },
    updateDay() {
      this.$emit('update')
    },
    updateWeekTitle() {
      if (this.week.week) {
        this.$emit('enableWeek', true)
      }
    },
    changed(ids) {
      const cDayVideoIds = this.currentDay.contents.map(function(day) {
        return day.exercise ? day.exercise.id : day.id
      })

      // compare two arrays
      const unique1 = cDayVideoIds.filter(o => ids.indexOf(o) === -1)
      const unique2 = ids.filter(o => cDayVideoIds.indexOf(o) === -1)

      const removeId = unique1.concat(unique2)

      if (removeId.length > 0) {
        for (let i = 0; i < removeId.length; i++) {
          const index = this.currentDay.contents.findIndex(day =>
            day.exercise
              ? day.exercise.id === removeId[0]
              : day.id === removeId[0]
          )
          if (index > -1) {
            this.currentDay.contents.splice(index, 1)
          }
        }
      }

      for (let i = 0; i < ids.length; i++) {
        // check if id exist in current day
        if (
          this.currentDay.contents.some(day =>
            day.exercise ? day.exercise.id === ids[i] : day.id === ids[i]
          )
        ) {
        } else {
          this.currentDay.contents.push(
            this.exercises.find(exercise => exercise.id === ids[i])
          )
        }
      }
    },
    uploadThumbnail() {
      this.$refs.triggerUpload.openFile()
    },
    onMediaLoad(data) {
      this.currentDay.thumb_nail = [
        {
          file: data.file.get('file'),
          url: data.url
        }
      ]
    },
    findIndex(exercise) {
      const index = this.currentDay.contents.findIndex(content => {
        return content.exercise
          ? content.exercise.id === exercise.id
          : content.id === exercise.id
      })

      return index
    },
    isInContents(exercise) {
      const index = this.currentDay.contents.findIndex(content => {
        return content.exercise
          ? content.exercise.id === exercise.id
          : content.id === exercise.id
      })

      return index >= 0
    },
    checkThumbNail(currentDay) {
      return currentDay?.thumb_nail?.[0]?.url || currentDay?.thumb_nail?.url
    },
    menuAction(menu, day, index) {
      switch (menu) {
        case programMenu().EDIT:
          //
          this.$emit('onEdit', { day, index })
          break
        case programMenu().DELETE:
          //
          this.$emit('onRemove', { day, index })
          break
      }
    },
    renderThumbnail(day) {
      return day.thumb_nail[0] ? day.thumb_nail[0].url : day.thumb_nail.url
    },
    get
  }
}
</script>

<style lang="scss" scoped>
.v-list-item__subtitle {
  display: -webkit-box;
  max-width: 100%;
  margin: 0 auto;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}

.image-container {
  max-height: 140;
  width: auto;
}

.upload-container {
  width: 100%;
  height: auto;
  border-radius: 6px;
  border: dashed 2px #2c66d54d;
  background-color: #2c66d50a;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: relative;
  cursor: pointer;
  user-select: none;

  &.has-error {
    border: dashed 2px #a31f314d;
  }

  .v-image {
    flex-grow: 0;
  }

  .upload-label span {
    box-shadow: 0 12px 20px rgba(44, 102, 213, 0.4);
    border-color: #0062cc;
    background-color: #0062cc;
    color: #ffffff;
    padding: 4px 32px;
    border-radius: 8px;
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    margin-bottom: 12px;
  }
}
</style>
